.app-experience-item {
  padding-bottom: 6rem;

  .app-experience-item-title {
    gap: 0.5rem;
  }

  .app-experience-item-subtitle {
    padding: 1rem 0;
  }

  .app-experience-item-link-description {
    padding: 1rem 0 0.3rem 0;
  }

  .app-experience-images-container {
    gap: 2rem;

    img {
      border-radius: 2%;
    }

    @media screen and (max-width: 1100px) {
      display: none;
    }
  }

  .app-experience-images-container-mobile {
    max-width: 100%;
    gap: 1rem;
    align-items: center;
    justify-content: center;

    img {
      border-radius: 2%;
    }

    @media screen and (min-width: 1100px) {
      display: none;
    }
  }

  div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    h1 {
      transition: color 0.3s ease-in-out;
      color: var(--text-color);
      font-size: 2rem;
      font-weight: 400;
      padding-bottom: 0;

      a {
        transition: all 0.3s ease-in-out;

        &:hover {
          color: var(--secondary-color);
        }
      }

      @media screen and (max-width: 1100px) {
        font-size: 1.5rem;

        a {
          font-size: 1.5rem;
        }
      }

      @media screen and (min-width: 2600px) {
        font-size: 2.4rem;
      }
    }

    padding-bottom: 0.25rem;
  }

  p {
    font-size: 1.25rem;

    transition: color 0.3s ease-in-out;

    color: var(--subtext-color);

    @media screen and (min-width: 2600px) {
      font-size: 1.5rem;
    }
  }

  ul {
    padding-left: 0.85rem;
    list-style-type: disc;

    li {
      &::marker {
        font-size: 1rem;
      }

      transition: color 0.3s ease-in-out;
      color: var(--text-color);
      font-size: 1.25rem;
      padding: 0.5rem 0;

      @media screen and (min-width: 2600px) {
        font-size: 1.5rem;
      }

      a {
        transition: all 0.3s ease-in-out;

        &:hover {
          color: var(--secondary-color);
        }
      }
    }
  }

  .app-experience-item-links {
    padding-left: 0;
    display: flex;
    gap: 0 1rem;
    flex-wrap: wrap;

    li {
      list-style: none;
      font-size: 1.25rem;

      a {
        transition: all 0.3s ease-in-out;

        &:hover {
          color: var(--secondary-color);
        }
      }

      @media screen and (min-width: 2600px) {
        font-size: 1.5rem;
      }
    }
  }
}
