.app-contact {
  padding-top: 10rem;

  h1 {
    transition: color 0.3s ease-in-out;
    color: var(--text-color);
    font-size: 3.5rem;
    padding-bottom: 2rem;

    @media screen and (min-width: 2600px) {
      font-size: 4.2rem;
    }
  }

  p {
    transition: color 0.3s ease-in-out;
    color: var(--subtext-color);
    padding-bottom: 2rem;
    font-size: 1.25rem;

    @media screen and (min-width: 2600px) {
      font-size: 1.5rem;
    }
  }

  form {
    p {
      transition: color 0.3s ease-in-out;
      color: var(--text-color);
      padding-bottom: 2rem;
      font-size: 1.25rem;

      @media screen and (min-width: 2600px) {
        font-size: 1.5rem;
      }

      input {
        font: var(--font-base);
        padding: 0.6rem 0;
        font-size: 1rem;
        height: 2rem;
        width: 100%;
        background-color: transparent;
        border: none;
        border-bottom: 2px solid var(--text-color);
        transition: color 0.3s ease-in-out;
        color: var(--text-color);

        @media screen and (min-width: 2600px) {
          height: 2.4rem;
          font-size: 1.2rem;
        }

        &:focus {
          outline: none;
        }

        &:autofill {
          background-color: transparent;
        }

        &:-webkit-autofill {
          background-color: transparent;
        }
      }

      textarea {
        font: var(--font-base);
        padding: 0.6rem 0;
        font-size: 1rem;
        height: 8rem;
        width: 100%;
        background-color: transparent;
        border: none;
        border-bottom: 2px solid var(--text-color);
        transition: color 0.3s ease-in-out;
        color: var(--text-color);

        @media screen and (min-width: 2600px) {
          height: 9.6rem;
          font-size: 1.2rem;
        }

        &:focus {
          outline: none;
        }

        &:autofill {
          background-color: transparent;
        }

        &:-webkit-autofill {
          background-color: transparent;
        }
      }

      button {
        font: var(--font-base);
        background-color: transparent;
        transition: color 0.3s ease-in-out;
        transition: font-size 0.3s ease-in-out;
        color: var(--text-color);
        cursor: pointer;
        font-size: 1.25rem;
        border: none;

        @media screen and (min-width: 2600px) {
          font-size: 1.5rem;
        }

        &:hover {
          color: var(--secondary-color);
          font-size: 1.28rem;
        }
      }
    }
  }

  @media screen and (max-width: 1100px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
