.app-about {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 14rem;
  flex-direction: column;

  h1 {
    transition: color 0.3s ease-in-out;
    color: var(--text-color);
    font-size: 3.5rem;
    padding-bottom: 2rem;

    @media screen and (min-width: 2600px) {
      font-size: 4.2rem;
    }
  }

  h2 {
    transition: color 0.3s ease-in-out;
    color: var(--text-color);
    padding-bottom: 2rem;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 2.5rem;

    &:last-child {
      padding-bottom: 4rem;
    }

    @media screen and (min-width: 2600px) {
      font-size: 1.5rem;
      line-height: 3rem;
    }
  }

  @media screen and (max-width: 1100px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
