.app-experience {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 14rem;
  flex-direction: column;
  gap: 5rem;

  h1 {
    transition: color 0.3s ease-in-out;
    color: var(--text-color);
    font-size: 3.5rem;
    padding-bottom: 2rem;

    @media screen and (min-width: 2600px) {
      font-size: 4.2rem;
    }
  }

  @media screen and (max-width: 1100px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
