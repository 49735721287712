.app-project {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 10rem;
  flex-direction: column;
  gap: 5rem;

  h1 {
    transition: color 0.3s ease-in-out;
    color: var(--text-color);
    font-size: 3.5rem;
    padding-bottom: 2rem;

    @media screen and (min-width: 2600px) {
      font-size: 4.2rem;
    }
  }

  @media screen and (max-width: 1100px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  p {
    button {
      font: var(--font-base);
      background-color: transparent;
      transition: color 0.3s ease-in-out;
      transition: font-size 0.3s ease-in-out;
      color: var(--text-color);
      cursor: pointer;
      font-size: 1.25rem;
      border: none;

      @media screen and (min-width: 2600px) {
        font-size: 1.5rem;
      }

      &:hover {
        color: var(--secondary-color);
        font-size: 1.28rem;
      }
    }
  }
}
