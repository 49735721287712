.app-navbar {
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1100px) {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  @media screen and (max-width: 1099px) {
    max-width: 90%;
    margin: 0 auto;
  }
}

.app-navbar-title {
  p {
    transition: color 0.3s ease-in-out;
    color: var(--text-color);
    font-size: 1.4rem;
    cursor: default;

    @media screen and (min-width: 2600px) {
      font-size: 1.68rem;
    }
  }
}

.app-navbar-links {
  flex: 1;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  li {
    transition: color 0.3s ease-in-out;
    color: var(--text-color);
    margin: 0 0.75rem;
    font-size: 1.25rem;
    list-style: none;
    cursor: pointer;

    &:last-child {
      margin-right: 1.5rem;
    }

    &:hover {
      color: var(--secondary-color);
    }

    @media screen and (min-width: 2600px) {
      font-size: 1.5rem;
    }
  }

  @media screen and (max-width: 1100px) {
    display: none;
  }
}

.app-navbar-links-mobile {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-start;

  li {
    color: var(--text-color);
    font-size: 1.15rem;
    padding: 0.2rem 0;
    list-style: none;
    cursor: pointer;

    transform: all 0.3s ease-in-out;

    &:hover {
      color: var(--secondary-color);
    }
  }

  @media screen and (max-width: 1100px) {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  @media screen and (max-width: 1099px) {
    max-width: 90%;
    margin: 0 auto;
  }

  @media screen and (min-width: 1101px) {
    display: none;
  }
}

.dark-mode-toggle-web {
  margin-bottom: 2px;

  @media screen and (max-width: 1100px) {
    display: none;
  }
}

.dark-mode-toggle-mobile {
  margin-right: 5px;
}

.hamburger-menu {
  flex: 1;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media screen and (min-width: 1101px) {
    display: none;
  }
}

#menu__toggle {
  opacity: 0;
}

#menu__toggle:checked + .menu__btn > span {
  transform: rotate(45deg);
}

#menu__toggle:checked + .menu__btn > span::before {
  top: 0;
  transform: rotate(0deg);
}

#menu__toggle:checked + .menu__btn > span::after {
  top: 0;
  transform: rotate(90deg);
}

#menu__toggle:checked ~ .menu__box {
  left: 0 !important;
}

.menu__btn {
  font-size: 1rem;
  padding-top: 0.7rem;
  position: sticky;
  width: 26px;
  height: 26px;
  cursor: pointer;
  z-index: 1;
}

.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: var(--text-color);
  transition-duration: 0.25s;
}

.menu__btn > span::before {
  content: '';
  top: -8px;
}

.menu__btn > span::after {
  content: '';
  top: 8px;
}
