.app-intro-outer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 90vh;

  @media screen and (max-width: 1100px) {
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.app-intro {
  display: flex;
  flex-direction: column;
  max-width: 590px;

  @media screen and (max-width: 1500px) {
    max-width: 450px;
  }

  @media screen and (min-width: 2600px) {
    max-width: 840px;
  }
}

.app-intro-hello {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 5rem;

  @media screen and (max-width: 1100px) {
    height: 3rem;
  }
}

.hello-text {
  transition: color 0.3s ease-in-out;
  color: var(--text-color);
  font-size: 3.5rem;
  font-weight: 400;

  @media screen and (max-width: 1100px) {
    font-size: 2.1rem;
  }

  @media screen and (min-width: 2600px) {
    font-size: 4.2rem;
  }
}

.cursor {
  transition: color 0.3s ease-in-out;
  color: var(--text-color);
  font-size: 3.5rem;

  @media screen and (max-width: 1100px) {
    font-size: 2.1rem;
  }

  @media screen and (min-width: 2600px) {
    font-size: 4.2rem;
  }
}

.app-intro-emoji {
  max-width: min-content;
  margin-bottom: 2rem;
  font-size: 3.5rem;
  cursor: default;

  @media screen and (max-width: 1100px) {
    font-size: 2.1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  &:after {
    content: '😃';
  }

  &:hover:after {
    content: '😉';
  }

  @media screen and (min-width: 2600px) {
    font-size: 3.6rem;
  }
}

.app-intro-name {
  transition: color 0.3s ease-in-out;
  color: var(--text-color);
  font-size: 3.5rem;
  font-weight: 400;

  @media screen and (max-width: 1100px) {
    font-size: 2.1rem;
  }

  @media screen and (min-width: 2600px) {
    font-size: 4.2rem;
  }
}

.app-intro-description {
  transition: color 0.3s ease-in-out;
  color: var(--text-color);
  font-size: 1.25rem;
  margin-top: 2.5rem;
  font-weight: 400;

  a {
    transition: color 0.3s ease-in-out;
    color: var(--text-color);
    text-decoration: none;

    transition: all 0.3s ease-in-out;

    &:hover {
      color: var(--secondary-color);
    }
  }

  @media screen and (max-width: 1100px) {
    margin-top: 1rem;
  }

  @media screen and (min-width: 2600px) {
    font-size: 1.5rem;
  }
}

.app-intro-current-description {
  margin-bottom: 3rem;

  @media screen and (max-width: 1100px) {
    margin-bottom: 1rem;
  }
}

.profile-photo-mobile,
.profile-photo {
  width: 35%;

  border-radius: 50%;

  @media screen and (max-width: 1100px) {
    width: 85%;
    border-radius: 50%;
  }

  @media screen and (min-width: 2600px) {
    width: 36%;
  }
}

.profile-photo-mobile {
  margin: 0 auto;

  @media screen and (min-width: 1100px) {
    display: none;
  }
}

.profile-photo {
  @media screen and (max-width: 1100px) {
    display: none;
  }
}
