.app-skill {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 10rem;
  flex-direction: column;

  h1 {
    transition: color 0.3s ease-in-out;
    color: var(--text-color);
    font-size: 3.5rem;
    padding-bottom: 2rem;

    @media screen and (min-width: 2600px) {
      font-size: 4.2rem;
    }
  }

  p {
    transition: color 0.3s ease-in-out;
    color: var(--subtext-color);
    font-size: 1.25rem;

    @media screen and (min-width: 2600px) {
      font-size: 1.5rem;
    }
  }

  h2 {
    transition: color 0.3s ease-in-out;
    color: var(--text-color);
    padding: 1rem 0;
    font-size: 1.25rem;
    line-height: 2.5rem;
    font-weight: 400;

    @media screen and (min-width: 2600px) {
      font-size: 1.5rem;
    }
  }

  @media screen and (max-width: 1100px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
