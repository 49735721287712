.app-footer {
  padding-top: 14rem;
  padding-bottom: 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 1100px) {
    gap: 0.5rem;
    padding-left: 1rem;
    align-items: flex-start;
    flex-direction: column;
  }
}

.app-footer-title {
  p {
    transition: color 0.3s ease-in-out;
    color: var(--text-color);
    font-size: 1.4rem;
    cursor: default;

    @media screen and (min-width: 2600px) {
      font-size: 1.68rem;
    }
  }
}

.app-intro-social-links {
  a {
    margin-right: 1.5rem;
  }

  a:last-child {
    margin-right: 0;
  }
}

.app-footer-description {
  p {
    transition: color 0.3s ease-in-out;
    color: var(--text-color);
    font-size: 1.25rem;

    @media screen and (min-width: 2600px) {
      font-size: 1.5rem;
    }
  }
}
