#main {
  transition: background 0.3s ease-in-out;

  background-color: var(--primary-color);
  font-family: var(--font-base);
}

.app-animation-outer {
  height: 100vh;
  display: flex;
  justify-content: center;
}

.app-animation {
  margin: auto;
}

.app-lottie-animation {
  height: 400px;
  background-color: var(--primary-color);

  @media screen and (max-width: 1100px) {
    height: 300px;
  }
}

.app-contents {
  @media screen and (min-width: 1100px) {
    max-width: 68%;
    margin: 0 auto;
  }
}

.hover-underline-animation {
  position: relative;
}

.hover-underline-animation:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;

  color: var(--secondary-color);
  background-color: var(--secondary-color);
  transition: transform 0.3s ease-in-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
}

.hover-underline-animation-reversed {
  cursor: pointer;
  transition: color 0.3s ease-in-out;
  text-decoration: none;
  color: var(--text-color);
  position: relative;
}

.hover-underline-animation-reversed:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(1);
  height: 1px;
  bottom: 0;
  left: 0;

  transition: all 0.3s ease-in-out;

  color: var(--text-color);
  background-color: var(--text-color);
}

.hover-underline-animation-reversed:hover:after {
  transform: scaleX(0);
  color: var(--secondary-color);
  background-color: var(--secondary-color);
}

.app-skill-items {
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: center;
  flex-wrap: wrap;
  padding: 0;
  gap: 1.5rem;

  div {
    min-width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media screen and (min-width: 2600px) {
      min-width: 72px;
    }

    a {
      text-decoration: none;
    }

    div {
      align-items: center;
      height: 40px;
      width: 40px;

      @media screen and (max-width: 1100px) {
        height: 30px;
        width: 30px;
      }

      @media screen and (min-width: 2600px) {
        height: 48px;
        width: 48px;
      }
    }

    p {
      text-align: center;
      max-width: 60px;
      height: 10px;
      font-size: 0.8rem;
      color: var(--subtext-color);
      opacity: 1;
      margin-top: 0.2rem;

      transition: all 0.3s ease-in-out;

      @media screen and (max-width: 1100px) {
        font-size: 0.7rem;
      }

      @media screen and (min-width: 2600px) {
        max-width: 72px;
        font-size: 0.96rem;
      }
    }

    @media screen and (min-width: 1100px) {
      &:hover {
        p {
          color: var(--secondary-color);
        }
      }
    }
  }

  @media screen and (max-width: 1100px) {
    gap: 1rem;
  }
}
