.app-intro-social-links {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  a {
    font-size: 1.25rem;
    color: var(--text-color);
    text-decoration: none;

    transition: all 0.3s ease-in-out;

    &:hover {
      color: var(--secondary-color);
    }

    @media screen and (max-width: 1100px) {
      font-size: 1.15rem;
    }

    @media screen and (min-width: 2600px) {
      font-size: 1.5rem;
    }
  }

  a {
    margin-right: 4rem;
  }

  @media screen and (max-width: 1000px) {
    a {
      margin-right: 2rem;
    }
  }

  @media screen and (max-width: 700px) {
    a {
      margin-right: 1rem;
    }
  }

  a:last-child {
    margin-right: 0;
  }
}
