@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');

:root {
  --font-base: 'DM Sans', sans-serif;
}

body {
  overflow-y: overlay;
  -ms-overflow-style: none; /* IE and Edge */
}

body::-webkit-scrollbar {
  display: none;
}

[data-theme='light'] {
  /* Theme Colors */
  --primary-color: #dddddd;
  --secondary-color: #313bac;

  /* Specific Colors */
  --text-color: #020013;
  --subtext-color: #646464;
}

[data-theme='dark'] {
  /* Theme Colors */
  --primary-color: #020013;
  --secondary-color: #b0befd;

  /* Specific Colors */
  --text-color: #dddddd;
  --subtext-color: #999999;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
}
